<script lang="ts">
    import { onMount } from 'svelte';
    import { _, locale } from 'svelte-i18n';
    import MdLanguage from 'svelte-icons/md/MdLanguage.svelte';

    let menu: HTMLDetailsElement;

    onMount(() => {
        window.addEventListener('click', function (e) {
            if (!menu.contains(e.target as Node)) {
                menu.open = false;
            }
        });
    });
</script>

<details bind:this={menu}>
    <summary>
        <div class="h-5 w-5">
            <MdLanguage />
        </div>
        {#if $locale === 'es'}
            ES
        {:else}
            EN
        {/if}
    </summary>
    <ul class="p-2 bg-base-100 right-0">
        <li>
            <button on:click={() => locale.set('en')}>
                {$_('en')}
            </button>
        </li>
        <li>
            <button on:click={() => locale.set('es')}>
                {$_('es')}
            </button>
        </li>
    </ul>
</details>
